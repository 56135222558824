<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Devices
			.widget-body
				
				.row
					.col-sm-8.form-inline
						.input-group.input-group-inline.input-group-rounded
							input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
						| &nbsp;
						.form-group
							button.btn.btn-primary(type="button", @click="loadData()") Go
					.col-sm-4.text-right
						router-link.btn.btn-success(:to="{name:'device-add'}")
							i.la.la-plus
							| Add
				
				p.clearfix &nbsp;
				
				.ov-table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Active
								th Name
								th IP
								th Created
								th Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="6") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td
									span(v-if="r.status===0")
										i.la.la-times
									span(v-if="r.status===1")
										i.la.la-check
								td {{ r.ip }}
								td {{ r.name }}
								td {{ r.created_at }}
								td.actions
									router-link.btn.btn-sm.btn-secondary(:to="{name:'device-edit', params: {id:r.id}}")
										i.la.la-pencil
									| &nbsp;
									a.btn.btn-sm.btn-secondary(href="")
										i.la.la-trash
					paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
export default {
  name: 'DeviceList',
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 10,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
      };
      try {
        let resp = await this.$ovReq.get('device/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
